import React from "react"

import Layout from "@src/components/Layout"
import * as styles from "./PrivacyPolicy.module.scss"

const PrivacyPolicy = ({ location }) => {
  return (
    <Layout
      title="Terms of Service"
      description="Canonic's Terms of Service"
      url="https://canonic.dev/terms-of-service"
      location={location}
      switchOnId={null}
    >
      <div className={styles.privacyPolicy}>
        <header>
          <h1>Canonic Terms of Service</h1>
          <p>
            <em>
              <b>EFFECTIVE DATE:</b> October 15, 2020
            </em>
          </p>
        </header>
        <div>
          <p>
            THIS IS A LEGAL AGREEMENT BETWEEN YOU, THE POTENTIAL USER AND
            CANONIC INC. SUBJECT TO ANY PROVISION CONTAINED HEREIN IN THIS
            DOCUMENT, THIS DOCUMENT SHALL GOVERN THE TERMS FOR AVAILING ANY
            SERVICES PROVIDED BY CANONIC INC. BY USING THIS WEBSITE YOU AGREE TO
            BE BOUND BY TERMS STATED HEREIN IN THIS DOCUMENT.. YOU AGREE BY
            ACCESSING THIS WEBSITE YOU HAVE READ, UNDERSTOOD AND AGREE TO ALL
            THE TERMS AND CONDITIONS AS STATED IN THIS DOCUMENT. IF YOU DO NOT
            AGREE TO ANY OF THE TERMS YOU SHOULD NOT USE THE WEBSITE AND
            SERVICES OFFERED BY CANONIC INC. FURTHER, THIS DOCUMENT IS AN
            ELECTRONIC RECORD IN TERMS OF THE INDIAN INFORMATION TECHNOLOGY ACT,
            2000 (“<b>IT ACT</b>”) AND RULES ISSUED THEREUNDER, AS APPLICABLE
            AND THE PROVISIONS PERTAINING TO ELECTRONIC RECORDS IN VARIOUS
            STATUTES AS AMENDED BY THE IT ACT AND IS PUBLISHED IN ACCORDANCE
            WITH THE PROVISIONS OF APPLICABLE LAWS, INCLUDING THE (INDIAN)
            CONSUMER PROTECTION (E-COMMERCE) RULES 2020, THAT REQUIRE PUBLISHING
            THE RULES AND REGULATIONS, PRIVACY POLICY AND TERMS AND CONDITIONS
            FOR ACCESS OR USAGE OF THE WEBSITE. THIS ELECTRONIC RECORD IS
            GENERATED BY A COMPUTER SYSTEM AND DOES NOT REQUIRE ANY PHYSICAL OR
            DIGITAL SIGNATURES.
            <p>Copyright CANONIC INC. All Rights Reserved</p>
            <p>
              All the information, material, content and their respective
              arrangement on the Site, shall be owned by at all point in time,
              unless otherwise indicated.
            </p>
            <p>Trademarks</p>
            <p>
              Canonic Inc, its subsidiaries, affiliates, contractors and/or
              participating corporations are the owners of the trade and service
              marks appearing on this website at all point in time, and all
              rights are reserved with respect to such trade and service marks.
              <p />
            </p>
          </p>
          <h3>1. ACCEPTANCE AND MODIFICATIONS</h3>
          <p>
            Canonic Inc a company duly incorporated and having its office at
            308,Willow Way, Chester Springs Pennsylvania 19425 and its parents,
            affiliates, and/or franchisors (collectively "<b>Company</b>", “
            <b>We</b>,” “<b>Our</b>” or “<b>Us</b>”). provides the services for
            creating backend services including but not limited for websites and
            mobile applications (collectively “<b>Services</b>”) and other
            allied services through its website or mobile application maintained
            at{" "}
            <u>
              <a href="https://canonic.dev/">www.canonic.dev</a>
            </u>
            , and any associated websites, mobile sites or applications,
            products, software and other services (collectively “<b>Site</b>”).
            This page states the terms and conditions under which the “
            <b>User</b>”, “<b>Customer</b>” or “<b>You</b>” may access and use
            the Site for availing the Services (“
            <b>Terms</b>”). These Terms establish the terms, conditions, rights
            and responsibilities applicable to Your use of the Site and
            Services. Unless explicitly stated otherwise, any new features that
            augment or enhance the current Site and Services shall be subject to
            these Terms. If You have entered into a separate agreement with the
            Company governing your use of the Site and/or Services (“
            <b>Service Agreement</b>”), the Service Agreement will additionally
            apply to Your use of the Site and/or Services, and will govern in
            the event of any conflict or inconsistency with these Terms. Your
            breach of any of these Terms or the Service Agreement causes an
            automatic termination of the rights and licenses granted to You
            hereunder.
          </p>
          <p>
            <b>
              PLEASE READ THESE TERMS CAREFULLY BEFORE USING THE SITE AND THE
              SERVICES. BY USING THE SITE AND/OR THE SERVICES, YOU ARE AGREEING
              TO COMPLY WITH THESE TERMS. IF YOU DO NOT AGREE TO BE BOUND BY
              THESE TERMS, DO NOT USE THE SITE OR THE SERVICES.
            </b>
          </p>
          <p>
            We may modify these Terms or any of our other policies or
            guidelines, at any time upon notice to You. We may provide that
            notice in a variety of ways, including, without limitation, sending
            You an email (if you have an Account), posting a notice on the Site,
            or posting the revised Terms on the Site and revising the date at
            the top of these Terms. Any changes or modifications will be
            effective upon Us providing such notice that these Terms have been
            modified. You acknowledge that your continued use of the Site or any
            of the Services following such notice constitutes Your acceptance of
            the modified Terms.. If You do not agree with the new version, You
            must stop using the Site and Services and terminate Your Account, if
            You have one.
          </p>
          <h3>2. AGE REQUIREMENTS AND ELIGIBILITY</h3>
          <p>
            You may not use the Services and may not accept these Terms if (1)
            You are not of legal age to form a binding contract with the
            Company, or (2) You are a person barred from receiving the Services
            under the laws of the jurisdiction in which You are resident or from
            which You access and use the Site and the Services. By using or
            attempting to use the Site and Services, You represent that You meet
            the age requirements and that You are able to enter into legally
            binding contracts, including these Terms.
          </p>
          <h3>3. PROPRIETARY RIGHTS</h3>
          <p>
            We and/or Our licensors own all intellectual property and
            proprietary rights, title and interest in and to the Site and the
            Services, including without limitation the trademarks registered
            under the name of the Company of any of its affiliates including the
            Site copyrights. Except for the limited use rights granted to You in
            these Terms, You agree that You do not have and will not acquire any
            right, title, or interest in any of our intellectual property or
            other proprietary rights. Any rights not expressly granted in these
            Terms are expressly reserved.
          </p>
          <h3>4. ACCESS</h3>
          <p>
            Please note, the Paid Services (as defined below) are only offered
            in the territorial jurisdiction of United States of America and
            India, however, any User who is not residing in United States of
            America or India may use the unpaid Services offered by the Company
            until the Company make any decision to either provide the Paid
            Services to such jurisdiction or completely stops providing any
            Services in such jurisdiction.
          </p>
          <h3>5. USE OF THE SITE AND THE SERVICES</h3>
          <p>
            We hereby grant You a limited, revocable, non-exclusive,
            non-transferable, non-sublicensable license to access and use the
            Site and the Services for their intended purposes (namely, for
            creating back end services, storing of the website and mobile
            application data and related services) in accordance with these
            Terms and any Service Agreement. Except for the limited rights and
            licenses expressly granted to You herein, no other license is
            granted, no other use is permitted and the Company (and its
            licensors) shall retain all rights, and interests (including all
            intellectual property and proprietary rights) in and to the Site and
            the Services. These limited rights and licenses may be revoked at
            any time according to Section 10 (Termination of Service) below.
          </p>
          <p>
            When using the Site and/or Services, You agree to comply with all
            applicable federal, state, and local laws including but not limited
            to, copyright law. Except as expressly permitted in these Terms, You
            may not use, reproduce, distribute, create derivative works based
            upon, publicly display, publicly perform, publish, transmit, or
            otherwise exploit the Site content or any content that is not Your
            User Content for any purpose whatsoever without obtaining prior
            written consent from Us or, in the case of third-party content, its
            respective owner.
          </p>
          <p>
            You may not cause any harm to or otherwise interfere with the Site
            and/or Services, by (but not limited to):
          </p>
          <ul>
            <li>
              removing, altering, covering, or distorting any copyright,
              trademark, or other proprietary rights notice on the Site, Site
              content or any content that is not your Content;
            </li>
            <li>
              encumber, sublicense, transfer, rent, lease, time-share or use the
              Services in any service bureau arrangement or otherwise for the
              benefit of any third party;
            </li>
            <li>
              circumventing, disabling or otherwise interfering with
              security-related features of the Site or Services including,
              without limitation, any features that prevent or restrict use or
              copying of any content or enforce limitations on the use of the
              Site or Services;
            </li>
            <li>
              using an automatic device (such as a robot or spider) or manual
              process to monitor the activity on, copy or “scrape” the Site, the
              Services or their content for any purpose without our express
              written permission, except for search engines, traffic counters,
              or similar basic performance monitoring technology;
            </li>
            <li>
              transmitting, using, distributing or uploading programs or
              material that contain malicious code, such as viruses, timebombs,
              cancelbots, worms, trojan horses, spyware, or other potentially
              harmful programs or other material or information;
            </li>
            <li>
              forging any TCP/IP packet header or part of the header information
              in any email or newsgroup posting for any reason;
            </li>
            <li>
              collecting or harvesting any personal information from the Site or
              the Services, including but not limited to user names, passwords,
              or email addresses;
            </li>
            <li>
              soliciting other users to join or become members of any commercial
              online service or other organization without Our prior written
              approval;
            </li>
            <li>
              make any unauthorized use of the Site, including collecting
              usernames and/or email addresses of users by electronic or other
              means for the purpose of sending unsolicited email, or creating
              user accounts by automated means or under false pretences;
            </li>
            <li>
              attempting to or interfering with the proper working of the Site
              or the Services or impairing, overburdening, or disabling the
              same;
            </li>
            <li>
              modifying, creating derivative works from, decompiling, reverse
              engineering, or disassembling any portion of the Site or Services
              or any Content that is not Your User Content;
            </li>
            <li>
              disassemble, decompile, reverse engineer or use any other means to
              attempt to discover any source code of the Site, or the underlying
              ideas, algorithms or trade secrets therein;
            </li>
            <li>
              using network-monitoring software to determine the architecture of
              or extract usage data from the Site;
            </li>
            <li>
              engaging in any conduct, or encouraging or assisting any third
              party in any conduct, that violates any local, state or federal
              law, either civil or criminal;
            </li>
            <li>
              engaging in any activity, or encourage or assist any third party
              in any activity, that violates these Terms;
            </li>
            <li>impersonating another user, person, or entity;</li>
            <li>
              interfering with or disrupting the access of any user, host or
              network via any means including without limitation by overloading,
              flooding, spamming, scripting content creation, or linking to
              coupon sites;
            </li>
            <li>
              violating of any laws, including any extra jurisdictional law;
            </li>
            <li>
              engaging in any conduct that restricts or inhibits any other user
              from using or enjoying the Site or Services;
            </li>
            <li>
              harass, annoy, intimidate, or threaten any of Our employees or
              agents engaged in providing any portion of the Site and/or
              Services to You;
            </li>
            <li>
              make improper use of our support services or submit false reports
              of abuse or misconduct;
            </li>
            <li>
              use or allow the transmission, transfer, export, re-export or
              other transfer of any product, technology or information it
              obtains or learns pursuant to these Terms (or any direct product
              thereof) in violation of any export control or other laws and
              regulations of the United States or any other relevant
              jurisdiction;
            </li>
            <li>
              use any of the Company’s Confidential Information (as defined
              below) to create any website, application, platform, service,
              software, documentation, or data that is similar to any aspect of
              the Services or Site; or
            </li>
            <li>
              use the Site as part of any effort to compete with us or otherwise
              use the Site and/or the Services including for any
              revenue-generating endeavour or commercial enterprise.
            </li>
          </ul>
          <p>
            You will cooperate with Us to investigate any suspected or actual
            activity that We suspect may be in breach of these Terms
          </p>
          <h3>6. ACCOUNTS</h3>
          <p>
            To access and use certain Services through the Site, You may need to
            set up an account (“<b>Account</b>”). To set up an Account, You will
            be asked to link any of Your pre-existing google, facebook or github
            account. At the time of creation of Account we only store name and
            email address, however, once Your Account is created, We may ask You
            to provide additional information such as Your company name, phone
            number, how You discovered Us, Your department in Your company and
            any other information/details which may help Us to modify and
            develop Our Services.. You must provide accurate information and
            keep all Account information current.
          </p>
          <p>
            You shall not select or use as a username a name: (a) of another
            person with the intent to impersonate that person; (b) subject to
            any rights of a person other than You without appropriate
            authorization; or (c) that is otherwise offensive, vulgar or
            obscene.
          </p>
          <p>
            You are responsible for maintaining the confidentiality of Your
            password and Account. You are fully responsible for all uses of Your
            password, Account and Your computer, including any unauthorized use.
            You agree to: (a) keep your password confidential and not share it
            with anyone else, and (b) immediately notify Us of any unauthorized
            use of Your password or Account.
          </p>
          <p>
            You acknowledge and agree that We are authorized to act on
            instructions received through use of Your password and Account, and
            that We may, but are not obligated to, deny access or block any
            transaction made through use of Your password or Account without
            prior notice if We believe Your password and Account are being used
            by someone other than You, or for any other reason.
          </p>
          <p>
            If You are accessing, browsing and using the Site on someone else’s
            behalf; You represent that You have the authority to bind that
            person to all the terms and conditions herein. In the event that the
            person refuses to be bound as the principal to the Terms, You agree
            to accept liability for any harm caused by any wrongful use of the
            Site resulting from such access or use of the Site in whatsoever
            nature.
          </p>
          <p>
            If You know or have reasons to believe that the security of Your
            Account has been breached, You should contact Us immediately at the
            'Contact Information' provided below. If We have found a breach or
            suspected breach of the security of Your Account, We may require You
            to change Your password, temporarily or permanently block or suspend
            Your Account without any liability to the Company.
          </p>
          <h3>7. YOUR CONTENT</h3>
          <p>
            As between the Company and You, unless otherwise specified, You own
            all intellectual property and other right, title and interest in and
            to any data, information, text, images, code and other content that
            You upload to or through the Site and/or Services or otherwise make
            (or allow any third party to make) material available by means of
            the Site or the Services or submit feedback and suggestions through
            the Services (collectively <b>“User Content”</b>). Except as
            expressly set forth in these Terms, the Company does not acquire any
            right, title or interest in or to the User Content. By uploading or
            submitting any User Content to or through the Site or Service, You
            grant to the Company, its licensees, sub-licensees and successors, a
            perpetual, worldwide, non-exclusive, transferable, irrevocable,
            royalty-free, sublicensable right and license to store, use, copy,
            modify, reproduce, adapt, display, translate, publish, publicly
            perform, publicly display, distribute, edit, reformat and create
            derivatives of User Content as we deem necessary to provide the Site
            and the Services, including but not limited to, to improve or
            enhance the Site and the Services, to develop new features and
            functionality, and for research, benchmarking, and other statistical
            purposes.. We do not share the User Content to any third party,
            however, we may access the User Content if any User makes a request
            to Us for deleting its User Content or personal data.
          </p>
          <p>
            You are solely responsible for all of Your User Content and for any
            harm or liability resulting from or arising out of such User
            Content. We have the right, but do not assume the obligation or
            responsibility, to monitor Your User Content and to determine
            whether it complies with applicable laws and these Terms and You
            agree to indemnify the Company for any claims, damages, costs, and
            liabilities arising from such User Content. You shall be responsible
            for maintaining appropriate security, protection and back up of the
            User Content. Further, You shall not store any sensitive data
            including any personally identifiable financial data, health data,
            biometric, etc. without Company’s prior written approval.
          </p>
          <p>
            The Company is not required to review any User Content, but may
            determine, in sole discretion, that certain Content violates these
            Terms. We may remove such User Content, suspend Your Account,
            suspend access to the Site, or take any other steps that We deem
            appropriate in such a case. Where applicable, the Company may still
            collect subscription fees from suspended accounts. The Company does
            not guarantee the accuracy, reliability or quality of the User
            Content. You acknowledge that by using the Site, or by merely
            visiting the Site, You may be exposed to content that You may
            consider offensive, indecent or objectionable.
          </p>
          <p>
            By submitting any User Content relating to feedback, review, ratings
            etc., You represent and warrant that:
          </p>
          <ol>
            <li>
              You are the sole author and owner of the intellectual property
              rights thereto;
            </li>
            <li>
              All "moral rights" that You may have in such User Content have
              been voluntarily waived by You;
            </li>
            <li>All ratings or reviews that You post are accurate;</li>
            <li>
              You are at least the age of majority in the jurisdiction in which
              You reside;
            </li>
            <li>
              Use of the User Content does not violate these Terms and will not
              cause injury to any person or entity.
            </li>
            <li>
              You have obtained all rights and permissions necessary to publish
              and/or use the User Content in the manner in which You have
              published and/or used it;
            </li>
            <li>
              Our use of the User Content for the purpose of providing the
              Services (including, without limitation, downloading, copying,
              processing, or creating aggregations of the User Content) does not
              and will not (i) violate any applicable laws or regulations, or
              (ii) infringe, violate, or misappropriate the intellectual
              property rights of any third party; and
            </li>
            <li>
              the User Content does not contain threats or incitement to
              violence, and does not violate the privacy or publicity rights of
              any third party;
            </li>
          </ol>
          <p>
            You further agree and warrant that You shall not post or publish any
            User Content:
          </p>
          <ol>
            <li>That is known by You to be false, inaccurate or misleading;</li>
            <li>
              That infringe any third party's copyright, patent, trademark,
              trade secret or other proprietary rights or rights of publicity or
              privacy;
            </li>
            <li>
              That violate any law, statute, ordinance or regulation (including,
              but not limited to, those governing export control, consumer
              protection, unfair competition, anti-discrimination or false
              advertising);
            </li>
            <li>
              That are, or may reasonably be considered to be, defamatory,
              libellous, hateful, racially or religiously biased or offensive,
              unlawfully threatening or unlawfully harassing to any individual,
              partnership or corporation;
            </li>
            <li>
              For which You were compensated or granted any consideration by any
              third party;
            </li>
            <li>
              For which You were compensated or granted any consideration by any
              third party; That include any information that references other
              websites, addresses, email addresses, contact information or phone
              numbers; or
            </li>
            <li>
              That contains any computer viruses, worms or other potentially
              damaging computer programs or files.
            </li>
          </ol>
          <h3>8. THIRD-PARTY LINKS</h3>
          <p>
            The Site and the Services may provide links to third-party websites,
            advertisements, or resources. Because We have no control over such
            sites, advertisements, or resources, We are not responsible for the
            availability of such external websites or resources, and We do not
            endorse and are not responsible or liable for any content,
            advertising, products, or other materials on or available from such
            third-party sites or resources. We are not responsible or liable,
            directly or indirectly, for any damage or loss caused or alleged to
            be caused by or in connection with Your use of or reliance on any
            such content, goods, or services available on or through any such
            third-party website or resource. You should refer to the terms and
            policies of any third party sites You use to determine Your rights.
          </p>
          <h3>9. MODIFICATIONS TO THE SITE AND SERVICES</h3>
          <p>
            We reserve the right at any time in our sole discretion to modify,
            discontinue, or remove, temporarily or permanently, the Site and/or
            Services (or any part thereof) with or without notice. We will not
            be liable to you or to any third party for any such modification,
            suspension, removal, or discontinuance. We will use commercially
            reasonable efforts to make the Site available at all times, except
            for scheduled downtime and any unavailability caused by events
            beyond our reasonable control, such as fires, natural disasters,
            government actions, civil unrest, internet service provider failures
            or delays, or denial of service attacks that would not be stopped by
            the use of standard security measures.
          </p>
          <h3>10. TERMINATION OF SERVICE</h3>
          <p>
            We may, in our sole and absolute discretion and without notice or
            liability to You or any third party, immediately suspend, limit Your
            access to and/or terminate your access to the Site, Services and/or
            your Account for any reason or for no reason, including without
            limitation: (a) if We believe in Our sole discretion that You have
            violated or may violate these Terms, the Service Agreement (if any),
            or any applicable laws or regulations; (b) at the request of law
            enforcement, government agencies or courts; (c) if You ask Us to
            close Your Account; (d) if We discontinue or materially modify the
            Site or Services (or any part thereof); (e) if unexpected technical
            or security issues or problems arise; (f) if We believe in Our sole
            discretion that Your use or access to the Site, Services or your
            Account may create risk (including but not limited to legal risk)
            for the Company, or its contractual partners, or users; and/or (g)
            following extended periods of inactivity on Your Account (six (6)
            months or longer). Termination of Your access to the Site, Services
            and/or Your Account may include in Our sole discretion, but is not
            limited to: (x) removal of access to some or all offerings on the
            Site and Services; (y) the deletion of some or all of your User
            Content, Account information and/or other content associated with
            Your use of the Site, Services and/or your Account (or any part
            thereof) from the Site, our servers and/or directories; and/or (z)
            banning You from using the Site and Services by any available means,
            including without limitation by blocking Your IP address.
          </p>
          <p>
            Any suspension or termination shall not affect Your obligations to
            us under these Terms. The provisions of these Terms which by their
            nature should survive the suspension or termination of Your access
            to or use of the Site, Services and/or Your Account, shall survive
            including, but not limited to, the rights and licenses that You have
            granted hereunder, indemnities, releases, disclaimers, limitations
            on liability, provisions related to choice of law, dispute
            resolution, and all of the miscellaneous provisions set forth below.
          </p>
          <h3>11. USAGE INFORMATION</h3>
          <p>
            The Company owns all data regarding installation, registration, and
            use of the Site, and related to Site performance, including response
            times, load averages, usage statistics, and activity logs,
            (collectively, <b>“Usage Information”</b>). Usage Information
            includes information about the Site and any user components
            incorporated into the Site. Usage Information does not include any
            personally identifiable information, but may include aggregated
            information derived from Content. Usage Information is used to
            contribute to analytical models used by the Company, to monitor and
            improve the Site, and to perform the Company’s obligations under
            these Terms or Service Agreement.
          </p>
          <h3>12. INDEMNITY</h3>
          <p>
            You agree to indemnify, defend, and hold the Company, its
            affiliates, and its and their respective members, officers,
            directors, owners, employees, agents, partners, licensors, service
            providers, successors, and assigns (collectively, the{" "}
            <b>“Indemnified Parties”</b>) harmless from and against any
            third-party claims, liabilities, losses, damages, obligations,
            costs, and expenses (including but not limited to reasonable
            attorneys’ fees and costs) <b>(“Claims”)</b> arising out of or
            relating to your use of the Site or Services, your User Content,
            your breach of these Terms, and/or your acts or omissions relating
            to any of the foregoing. We have the right to control the defence,
            settlement and/or other resolution of any Claims, at your sole cost
            and expense. You may not settle or otherwise resolve any Claim
            without our express written permission.
          </p>
          <h3>13. RELEASE</h3>
          <p>
            In the event that You have a dispute with one or more other users of
            the Services, You release the Company (and its officers, directors,
            agents, subsidiaries, joint ventures and employees) from any claims,
            demands and damages (actual and consequential) of every kind and
            nature, known and unknown, suspected and unsuspected, disclosed and
            undisclosed, arising out of or in any way connected with such
            disputes.
          </p>
          <h3>14. DISCLAIMER OF WARRANTIES</h3>
          <p>
            <b>
              YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOUR USE OF THE SITE AND
              THE SERVICES IS AT YOUR SOLE RISK. THE SITE, SERVICES, AND THE
              SITE CONTENT ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS
              WITH ALL FAULTS. WITHOUT LIMITING THE FOREGOING AND TO THE FULLEST
              EXTENT OF THE LAW, THE INDEMNIFIED PARTIES EXPRESSLY DISCLAIM ALL
              WARRANTIES OF ANY KIND, INCLUDING ANY: (A) WARRANTIES THAT THE
              SITE, THE SERVICES, OR SITE CONTENT WILL MEET YOUR REQUIREMENTS OR
              SATISFACTION; (B) WARRANTIES CONCERNING THE AVAILABILITY,
              ACCURACY, SECURITY, USEFULNESS, TIMELINESS, OR INFORMATIONAL
              CONTENT OF THE SITE, SERVICES, OR SITE CONTENT; (C) WARRANTIES OF
              TITLE, NON-INFRINGEMENT, MERCHANTABILITY, OR FITNESS FOR A
              PARTICULAR PURPOSE; (D) WARRANTIES CONCERNING THE CAPACITY,
              COMPLETENESS, ACCURACY, OR RELIABILITY OF THE INFORMATION OR
              RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SITE AND/OR
              SERVICES; (E) WARRANTIES THAT YOUR USE OF THE SITE WILL BE SECURE
              OR UNINTERRUPTED; AND (F) WARRANTIES THAT THE SITE, THE SERVICES
              AND/OR SITE CONTENT WILL BE ERROR-FREE OR THAT ERRORS IN THE SITE,
              THE SERVICES AND/OR SITE CONTENT WILL BE CORRECTED. ANY MATERIAL
              DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SITE
              AND/OR THE SERVICES IS DONE AT YOUR OWN DISCRETION AND RISK AND
              THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR
              COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF
              ANY SUCH MATERIAL. NO ADVICE OR INFORMATION, WHETHER ORAL OR
              WRITTEN, OBTAINED BY YOU FROM THE COMPANY OR THROUGH OR FROM THE
              SERVICES SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THE
              TERMS. WE ASSUME NO RESPONSIBILITY FOR THE TIMELINESS, DELETION,
              MIS-DELIVERY OR FAILURE TO PROVIDE ANY CONTENT OR TO STORE ANY
              PERSONALIZATION SETTINGS OR CONTENT. NO ADVICE OR INFORMATION,
              WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM US OR THROUGH OR
              FROM THE SITE OR SERVICES SHALL CREATE ANY WARRANTY NOT EXPRESSLY
              STATED IN THESE TERMS.
            </b>
          </p>
          <h3>15. LIMITATION OF LIABILITY</h3>
          <p>
            <b>
              WE MAY USE THIRD PARTIES TO PROVIDE CERTAIN SERVICES ACCESSIBLE
              THROUGH THE SITE AND THE SERVICES. WE WILL NOT BE LIABLE TO YOU
              FOR THE ACTS OR OMISSIONS OF ANY THIRD PARTY. TO THE FULLEST
              EXTENT PERMITTED UNDER APPLICABLE LAW, UNDER NO CIRCUMSTANCES,
              INCLUDING, WITHOUT LIMITATION, THE NEGLIGENCE OF ANY PARTY, WILL
              THE INDEMNIFIED PARTIES BE LIABLE FOR ANY INDIRECT, INCIDENTAL,
              SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES, INCLUDING, WITHOUT
              LIMITATION, DAMAGES FOR LOSS OF PROFITS, LOSS OF LOSS OF USE, DATA
              LOSS, OR OTHER INTANGIBLE LOSSES (EVEN IF THE INDEMNIFIED PARTIES
              HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), RESULTING
              FROM, ARISING OUT OF, RELATING TO, OR IN ANY WAY CONNECTED WITH
              (A) YOUR USE OF, OR YOUR INABILITY TO USE, THE SITE OR SERVICES,
              (B) ANY ACTION YOU TAKE BASED ON THE INFORMATION YOU RECEIVE IN,
              THROUGH, OR FROM THE SITE OR THE SERVICES, (C) YOUR FAILURE TO
              KEEP YOUR PASSWORD OR ACCOUNT DETAILS SECURE AND CONFIDENTIAL, (D)
              THE COST OF PROCUREMENT OF SUBSTITUTE SERVICES RESULTING FROM ANY
              GOODS, DATA, INFORMATION, OR SERVICES PURCHASED OR OBTAINED OR
              MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO THROUGH OR FROM THE
              SERVICES; (E) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR
              TRANSMISSIONS OR DATA; (F) THE IMPROPER AUTHORIZATION FOR THE
              SERVICES BY SOMEONE CLAIMING SUCH AUTHORITY, OR (G) STATEMENTS OR
              CONDUCT OF ANY THIRD PARTY ON THE SERVICES, UNDER ANY THEORY OF
              LIABILITY. YOUR SOLE REMEDY, AND OUR AGGREGATE LIABILITY IN
              CONNECTION WITH THESE TERMS OR YOUR USE OF THE SITE AND THE
              SERVICES, SHALL IN ALL EVENTS BE LIMITED TO THE LOWER OF UNITED
              STATES DOLLARS 500 ($ 500) OR THE TOTAL AMOUNTS PAID BY YOU TO US
              FOR THE SERVICES GIVING RISE TO SUCH LIABILITY DURING THE SIX (6)
              MONTHS IMMEDIATELY PRECEDING THE CLAIM. PLEASE NOTE, THE
              LIMITATION OF LIABILITY AMOUNT MAY BE UPDATED SUBJECT TO THE
              CHANGE IN OUR PRICING. SOME JURISDICTIONS DO NOT ALLOW THE
              LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR
              CONSEQUENTIAL DAMAGES, SO SOME OF YOU SPECIFICALLY ACKNOWLEDGE AND
              AGREE THAT WE WILL NOT BE LIABLE FOR THE ILLEGAL, OFFENSIVE OR
              DEFAMATORY CONDUCT OF OTHERS, INCLUDING BUT NOT LIMITED TO ANY
              SUCH CONDUCT BY YOU, AND THAT YOU BEAR ALL RISK AND LIABILITY
              ASSOCIATED WITH YOUR CONDUCT AND CONTENT.
            </b>
          </p>
          <h3>16. DATA PRIVACY</h3>
          <p>
            We and Our service providers and other partners collect information
            from and/or about You when You use the Site and the Services, and
            process that information in accordance with our
            <a href="https://canonic.dev/privacy-policy">Privacy Policy</a> (as
            updated on the Site). By accepting these Terms You are also
            accepting the terms of the{" "}
            <a href="https://canonic.dev/privacy-policy">Privacy Policy</a>,
            which are incorporated herein by reference for all applicable
            purposes. If You do not agree to these Terms, please do not access
            the Site or use the Services.
          </p>
          <h3>17. PAYMENT</h3>
          <p>
            The Services may be free or charge or We may charge a fee for using
            the Services. If You are using a free version of the Services, We
            will notify You before any Services You are then using begin
            carrying a fee, and if You wish to continue using such free
            Services, You must pay all applicable fees for such Services
          </p>
          <p>
            If You purchase any of our paid Services <b>(“Paid Services”)</b>,
            You agree to pay Us the applicable fees and taxes and to additional
            terms specific to the Paid Services. Failure to pay these fees will
            result in the termination of Your Paid Services. Also, You agree
            that:
          </p>
          <ol>
            <li>
              Amount paid by You for Your purchase shall be in United States
              Dollars and may be subject to foreign exchange fees or differences
              in prices based on location (e.g. exchange rates).
            </li>
            <li>
              We may charge the fees and applicable taxes through any of the
              payment methods accepted on the Site and as may be updated from
              time to time <b>(“Payment Method”)</b>, Currently, we only accept
              credit card and debit cards payment as Payment Method and this
              list may be updated from time to time.
            </li>
            <li>
              For some Payment Methods, the issuer / payment service providers
              may charge you certain fees, such as foreign transaction fees or
              other fees relating to the processing of your Payment Method.
              Local tax charges may vary depending on the Payment Method used.
              Check with your Payment Method service provider for details.
            </li>
            <li>
              We may store and continue billing your Payment Method (e.g. credit
              card) even after it has expired, to avoid interruptions in your
              Paid Services and to use to pay other Services You may purchase.
            </li>
            <li>
              If You purchase a subscription, Your Payment Method automatically
              will be charged at the start of each subscription period for the
              fees and taxes applicable to that period. To avoid future charges,
              cancel before the renewal date, please refer to Section 19 on how
              to cancel or suspend Your Paid Services. The length of your
              billing cycle will depend on the type of subscription that You
              choose when You signed up for the Service. In some cases Your
              payment date may change, for example if your Payment Method has
              not successfully settled, when You change your subscription plan
              or if your Paid Service began on a day not contained in a given
              month. Unless You cancel Your subscription for Paid Service before
              Your billing date, You authorize Us to charge the subscription fee
              for the next billing cycle to Your Payment Method. In the event,
              We fail to collect the subscription fees automatically, We may
              send you payment link on your registered email id for collecting
              the subscription fee.
            </li>
            <li>
              If a payment is not successfully settled, due to expiration,
              insufficient funds, or otherwise, and You do not cancel your
              Account, We may suspend Your access to the Paid Service until We
              have successfully charged a valid Payment Method and in some cases
              You may be automatically downgraded to a free Service.
            </li>
            <li>
              You can update Your Payment Methods by going to the "Billing"
              page. We may also update Your Payment Methods using information
              provided by the payment service providers. Following any update,
              You authorize Us to continue to charge the applicable Payment
              Method(s).
            </li>
            <li>
              The amount paid by You for Your purchases is strictly
              non-refundable, however, on special request raised by any user,
              the Company at its sole discretion may refund the amount paid by
              such user if it is satisfied with the reason given by such user
              for refunding the amount.
            </li>
            <li>
              We may calculate taxes payable by You based on the billing
              information that You provide us at the time of purchase.
            </li>
            <li>
              You can get a copy of Your invoice through your Account under the
              “Invoices” section.
            </li>
          </ol>
          <p>
            Please note that any payment terms presented to You in the process
            of using or signing up for a Paid Service are deemed part of these
            Terms. Further, if You are in India, please note that effective
            October 1, 2021, Your bank may not be able to process any requests
            for automated payments on your credit/debit cards unless it complies
            with <i>Reserve Bank of India’s</i> e-mandate guidelines.
          </p>
          <p>
            We may change Our subscription plans to Paid Service and the price
            of Our Service from time to time; however, any price changes or
            changes to Your subscription plans to Paid Service will apply no
            earlier than 30 days following notice to You.
          </p>
          <h3>18. CANCELLATION AND REFUND</h3>
          <p>
            You can cancel Your subscription for Paid Service at any time, and
            You will continue to have access to the Paid Service through the end
            of your billing period. To the extent permitted by the applicable
            law, payments are non-refundable and we do not provide refunds or
            credits for any partial membership periods or unused Paid Service.
            To cancel, go to the "Billing" page and follow the instructions for
            cancellation. If You cancel Your subscription to a Paid Service,
            Your account will be automatically downgraded to the free Service
            close at the end of Your current billing period.
          </p>
          <h3>19. CONFIDENTIALITY </h3>
          <p>
            You agree that the business, technical and financial information of
            the Company, that is either designated in writing as confidential,
            or is disclosed in a manner that a reasonable person would
            understand the confidentiality of the information disclosed, shall
            be the confidential property of Company (“Confidential
            Information”). You agree to hold in confidence and not disclose any
            Confidential Information and shall similarly bind Your employees,
            consultants, and independent contractors, if applicable, and will
            use the Confidential Information only for the purpose for which it
            was disclosed.
          </p>
          <h3>20. U.S GOVERNMENT RIGHTS</h3>
          <p>
            The Site is not tailored to comply with industry-specific
            regulations (Health Insurance Portability and Accountability Act
            (HIPAA), Federal Information Security Management Act (FISMA), etc.),
            so if Your interactions would be subjected to such laws, You may not
            use this Site. You may not use the Site in a way that would violate
            the Gramm-Leach-Bliley Act (GLBA). Each of the documentation and the
            software components that constitute the Services is a "commercial
            item" as that term is defined at 48 C.F.R. § 2.101, consisting of
            "commercial computer software" and "commercial computer software
            documentation" as such terms are used in 48 C.F.R. § 12.212.
            Accordingly, if Customer is an agency of the US Government or any
            contractor therefore, Customer only receives those rights with
            respect to the Services and Documentation as are granted to all
            other end users, in accordance with (a) 48 C.F.R. § 227.7201 through
            48 C.F.R. § 227.7204, with respect to the Department of Defence and
            their contractors, or (b) 48 C.F.R. § 12.212, with respect to all
            other US Government users and their contractors.
          </p>
          <h3>21. MISCELLANEOUS</h3>
          <p>
            a. <u>Dispute Resolution</u>. To expedite resolution and control the
            cost of any dispute, controversy, or claim related to these Terms
            (each a <b>“Dispute”</b> and collectively, the <b>“Disputes”</b>)
            brought by either You or Us (individually, a <b>“Party”</b> and
            collectively, the <b>“Parties”</b>), the Parties agree to first
            attempt to negotiate any Dispute (except those Disputes expressly
            provided below) informally for at least thirty (30) days before
            initiating arbitration. Such informal negotiations commence upon
            written notice from one Party to the other Party. If the Parties are
            unable to resolve a Dispute through informal negotiations, the
            Dispute (except those Disputes expressly excluded below) will be
            finally and exclusively resolved by binding arbitration. YOU
            UNDERSTAND THAT WITHOUT THIS PROVISION, YOU WOULD NOT HAVE THE RIGHT
            TO SUE IN COURT AND HAVE A JURY TRIAL. Except where prohibited by
            law, as a condition of using the Site and/or Service, You agree that
            any and all Disputes arising out of or connected with the Sites
            and/or Service, which cannot be resolved as stated above, shall be
            resolved individually, without resort to any form of class action,
            exclusively by binding arbitration for full and final settlement of
            such Dispute, and judgment on the award rendered in the arbitration
            may be entered in any court having jurisdiction thereof. The
            arbitration may be conducted in person, through the submission of
            documents, by phone, or online. An award rendered by the
            arbitrator(s) may be entered and confirmed by the courts of the
            State of Delaware, County of Sussex, or the United States District
            Court for the District of Delaware. The Parties agree that any
            post-arbitration action seeking to enforce an arbitration award or
            action seeking equitable or injunctive relief shall be brought
            exclusively in the courts of the State of Delaware, County of
            Sussex, or the United States District Court for the District of
            Delaware. Except as otherwise provided herein, the Parties may
            litigate in a court to compel arbitration, stay proceedings pending
            arbitration, or to confirm, modify, vacate, or enter judgment on the
            award entered by the arbitrator.
          </p>
          <p>
            The Parties agree that the following Disputes are not subject to the
            above provisions concerning informal negotiations and binding
            arbitration: (a) any Disputes seeking to enforce or protect, or
            concerning the validity of, any of the intellectual property rights
            of a Party; (b) any Dispute related to, or arising from, allegations
            of theft, piracy, invasion of privacy, or unauthorized use; and (c)
            any claim for injunctive relief. If this provision is found to be
            illegal or unenforceable, then neither Party will elect to arbitrate
            any Dispute falling within that portion of this provision found to
            be illegal or unenforceable and such Dispute shall be decided by a
            court of competent jurisdiction within the courts listed for
            jurisdiction above, and the Parties agree to submit to the personal
            jurisdiction of that court.
          </p>
          <p>
            b. <u>Governing Laws</u>: These Terms, for all purposes, will be
            governed and interpreted according to the laws of the State of
            Delaware, regardless of where the User is based and without regard
            to its conflict of law provisions, and you expressly agree and
            consent to the exclusive jurisdiction and venue of the state and
            federal courts of the State of Delaware, for the resolution of any
            such Dispute.
          </p>
          <p>
            Acceptance of these Terms constitutes Your consent to be sued in
            such courts and to accept service of process outside the State of
            Delaware with the same force and effect as if such service had been
            made within the State of Delaware. You hereby agree to accept
            service of process for any action hereunder by certified mail return
            receipt requested which service shall have the same force and effect
            as though service had been affected by personal service in the
            applicable jurisdiction.
          </p>
          <p>
            In no event shall any Dispute brought by either Party related in any
            way to the Site be commenced more than one (1) year after the cause
            of action arose. If this provision is found to be illegal or
            unenforceable, then neither Party will elect to arbitrate any
            Dispute falling within that portion of this provision found to be
            illegal or unenforceable and such Dispute shall be decided by a
            court of competent jurisdiction within the courts listed for
            jurisdiction above, and the Parties agree to submit to the personal
            jurisdiction of that court.
          </p>
          <p>
            c. <u>Waiver and Severability of Terms.</u> The failure of the
            Company to exercise or enforce any right or provision of these Terms
            shall not constitute a waiver of such right or provision. If any
            provision of these Terms is found by a court of competent
            jurisdiction to be void, invalid or unenforceable, then that
            provision shall be deemed severable from these Terms and shall not
            affect the validity and enforceability of any remaining provisions.
          </p>
          <p>
            d. <u>No Contest.</u> Our electronically or otherwise properly
            stored copy of these Terms will be deemed to be the true, complete,
            valid, authentic, and enforceable copy, and You agree that You will
            not contest the admissibility or enforceability of our copy of these
            Terms in connection with any action or proceeding arising out of or
            relating to these Terms.
          </p>
          <p>
            e. <u>Assignment.</u> We may assign our rights and duties under
            these Terms at any time to any third party without notice. You may
            not assign these Terms without Our prior written consent.
          </p>
          <p>
            f. <u>No Third Party Beneficiaries</u>. You agree that, except as
            otherwise expressly provided in the Terms, there shall be no third
            party beneficiaries to these Terms.
          </p>
          <p>
            g. <u>Corrections.</u> There may be information on the Site that
            contains typographical errors, inaccuracies, or omissions, including
            descriptions, pricing, availability, and various other information.
            We reserve the right to correct any errors, inaccuracies, or
            omissions and to change or update the information on the Site at any
            time, without prior notice.
          </p>
          <p>
            h. <u>Independent Contractors.</u> Your use of the Site and the
            Services is independent of the Company and not as an employee,
            agent, a partner, or a joint-venturer with the Company for any
            purpose.
          </p>
          <p>
            i. <u>Statute of Limitations.</u> You agree that regardless of any
            statute or law to the contrary, any claim or cause of action arising
            out of or related to the use of the Site, Services or these Terms
            must be filed within one (1) year after such claim or cause of
            action arose or be forever barred.
          </p>
          <p>
            j. <u>Entire Agreement.</u> These Terms (together with the Privacy
            Policy and any Service Agreement entered into between You and the
            Company) constitute the entire agreement between You and us with
            respect to Your access to and use of the Site and the Services, and
            any and all other written or oral agreements or understandings
            previously existing between You and Us with respect to the subject
            matter hereof are hereby superseded and cancelled.{" "}
          </p>
          <p>
            h. <u>Force Majeure.</u> Failure by either party to perform any
            obligation hereunder shall be excused if and for so long as such
            breach or failure to perform is caused by a force majeure event, and
            prompt notice thereof has been given to the other party. If either
            party fails to perform any duty or obligation hereunder as a result
            of a force majeure event, such party shall be required to fulfil its
            obligations hereunder within a reasonable time frame after the force
            majeure event ceases to exist.
          </p>
          <p>
            i. <u>Notice.</u> You agree that any notice that the Company is
            required to provide pursuant to these Terms can be given
            electronically, which may include an email to the email address you
            provide to the Company as part of your Account information. These
            notices can be about a wide variety of things, including responding
            to your questions, requests for additional information, and legal
            notices. You agree that such electronic notices satisfy any legal
            requirement that such communications be in writing. An electronic
            notice will be deemed to have been received on the day the email is
            sent to You, provided that the email is the same as the email
            address You provided as part of Your Account information.
          </p>
          <p>
            j. <u>Questions.</u> Please contact us with any questions regarding
            the Site, the Services or these Terms at support@canonic.dev. Any
            feedback or questions that You provide shall be deemed to be
            non-confidential, and We shall be free to use such information in
            accordance with Our Privacy Policy.
          </p>
          <p>
            k. <u>Appointment of Nodal Officer</u>: With respect to the Rule 4
            (1) (a) of the Indian Consumer Protection (E-Commerce) Rules{" "}
            <b>(“E-Com Rules”)</b>, 2020, We appoint Simranjot Singh, reachable
            at nodalofficer@canonic.dev, as nodal officer{" "}
            <b>(“Nodal Officer”)</b>. The Nodal Officer shall be responsible for
            ensuring compliance of Indian Consumer Protection Act, 2020{" "}
            <b>(“Consumer Protection Act”)</b> and any rules made thereunder.
          </p>
          <p>
            l. <u>Appointment of Grievance Officer</u>: With respect to the Rule
            4 (4) of the E-Com Rules we appoint Simranjot Singh, reachable at
            griveance@canonic.dev as its grievance officer{" "}
            <b>(“Grievance Officer”)</b>. Grievance Officer holds the position
            of ‘Compliance Officer’ in the Company. The Grievance Officer shall
            be responsible for handling the consumer complaints which can be
            raised by contacting the Grievance Officer.
          </p>
          <p>
            <em>Last Updated: April 7, 2022</em>
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default PrivacyPolicy
